import React from 'react'
import { Link } from 'gatsby'
import Flip from 'react-reveal/Flip';
import logo from '../img/newsletter-logo.png'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'
import Fade from 'react-reveal/Fade';
import MailChimp from "./MailChimp"


const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter" style={{background: '#363636', alignSelf: 'center'}}>       
        
        <div className="content has-text-centered has-text-white-ter">
          <div className="container has-text-white-ter">
          <div  class="columns is-mobile is-vcentered">
          <div class="column is-1" style={{background: '#363636', alignSelf: 'center'}}></div>
           <div class="column is-10" style={{background: '#363636', alignSelf: 'center'}}>
              <div class="tile is-ancestor">
                    <div class="tile is-2 is-parent" style={{ alignSelf: 'center', verticalAlign: 'middle'}}>
                      <article class="tile is-child green post">
                      <Flip left><img src={logo} alt="zeedigilab" /></Flip>
                      </article>
                    </div>
            
                    <div class="tile is-5 is-parent" style={{ alignSelf: 'center', verticalAlign: 'middle'}}>
                      <article class="tile is-child pink post">          
                      <h1 style={{color: 'white'}}><Fade bottom>Sign Up For Newsletter</Fade></h1>
                      <p class="description"><Fade bottom>Sign up to receive messages about new content, my thoughts on the web and subscribers only opportunities.</Fade></p>
                      </article>
                    </div>
            
                    <div class="tile is-5 is-parent" style={{ alignSelf: 'center', verticalAlign: 'middle'}}>
                      <article class="tile is-child gold post">
                      <Fade bottom><MailChimp /> </Fade>
                      </article>
                    </div>
            </div>    
        
        </div>
        <div class="column is-1"></div>
        </div>
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                   
                    <li>
                      <Link className="navbar-item" to="/services">
                        Services
                      </Link>
                    </li>
                    
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/blog">
                        Latest Posts
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
                
              </div>
              <br />
              <br />
              <div className="column is-4 social" style={{textAlign: 'right'}}>
                <a title="facebook" href="https://facebook.com/zeedigilab">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com/zeedigilab">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com/zeedigilab">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
               
              </div>
            </div>
            <br />
                <br />
                <div className="columns" style={{textAlign: 'center'}}>
                  <div className="column is-12">
                  © {new Date().getFullYear()}, Built with
                  {` `}
                  <span role="img" aria-label="heart emoji">
                  ❤️
                  </span>
                  {` `}
                in GatsbyJS
              </div>
              </div>
              <br />
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
